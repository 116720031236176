<template>
  <el-main>
    <el-button style="margin-bottom:20px" size="small" type="primary" @click="starAddFlag=true">添加类目</el-button>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="审核状态：">
        <el-select v-model="searchForm.status" size="small" placeholder="请选择">
          <el-option v-for="item in statusList" :key="item.status" :label="item.name" :value="item.status"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="getDataList(1)">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="datalsit" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="服务类目" align="center">
        <template v-slot="{row}">
          <span>{{row.level1_name}}</span>
          <span v-if="row.level2_name">/{{row.level2_name}}</span>
          <span v-if="row.level3_name">/{{row.level3_name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" align="center">
        <template v-slot="{row}">
          {{!row.status?'审核中':row.status===1?'已通过':'已拒绝'}}
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <!-- 开始添加类目弹窗 -->
    <el-dialog title="添加类目" :visible.sync="starAddFlag" width="700px" @closed="dialogClose">
      <div class="addContent">
        <el-form ref="form" :model="addVideoGoodsForm" :rules="rules" label-width="140px">
          <el-form-item label="视频号分类：" prop="category_info_level">
            <el-cascader ref="cascader" @change="typeChange" v-model="addVideoGoodsForm.category_info_level"
              :props="handelGoodsType()" style="width: 350px">
            </el-cascader>
          </el-form-item>
          <el-form-item label="类目资质要求：">
            <div class="richText">
              {{!addVideoGoodsForm.chooseThirdType.qualification_type?'--':addVideoGoodsForm.chooseThirdType.qualification}}
            </div>
          </el-form-item>
          <el-form-item label="商品资质类型要求：">
            <div class="richText">
              {{!Number(addVideoGoodsForm.chooseThirdType.product_qualification_type)?'--':addVideoGoodsForm.chooseThirdType.product_qualification}}
            </div>
          </el-form-item>
          <el-form-item label="上传资质证明："
            :prop="addVideoGoodsForm.chooseThirdType.qualification_type==1 || Number(addVideoGoodsForm.chooseThirdType.product_qualification_type) == 1?'category_info':'none'"
            v-if="addVideoGoodsForm.chooseThirdType.qualification_type || Number(addVideoGoodsForm.chooseThirdType.product_qualification_type)">
            <ReadyUploadSource type="live" :manyPath="addVideoGoodsForm.category_info" :isManyMax="10" :isMany="true"
              :url="4" @getSource="getCategoryInfo" @removeThis="removeCategoryInfo" @changeOrder="changeCategoryInfo">
            </ReadyUploadSource>
          </el-form-item>
          <el-form-item label="营业执照：">
            <ReadyUploadSource type="live" :manyPath="addVideoGoodsForm.license" :isManyMax="5" :isMany="true" :url="4"
              @getSource="getLicenseInfo" @removeThis="removeLicenseInfo" @changeOrder="changeLicenseInfo">
            </ReadyUploadSource>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="starAddFlag = false">取 消</el-button>
        <el-button type="primary" @click="sureAddFn">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    Paging,
    ReadyUploadSource
  },
  data () {
    function checkLevel (rule, value, callback) {
      if (value.length < 3) {
        callback(new Error('请选择详细类目'))
      } else {
        callback()
      }
    }
    function checkCategoryInfo (rule, value, callback) {
      if (!value.length) {
        callback(new Error('该类目必须上资质证明'))
      } else {
        callback()
      }
    }
    return {
      searchForm: {
        page: 1,
        rows: 10,
        status: -1
      },
      statusList: [
        { status: -1, name: '全部' },
        { status: 0, name: '待审核' },
        { status: 1, name: '通过' },
        { status: 9, name: '拒绝' },
      ],
      datalsit: [],
      total: 0,
      starAddFlag: false,
      addVideoGoodsForm: {
        category_info_level: [],
        chooseThirdType: {},
        category_info: [],
        license: []
      },
      rules: {
        category_info_level: [{ required: true, validator: checkLevel, trigger: 'blur' }],
        category_info: [{ required: true, validator: checkCategoryInfo, trigger: 'blur' }],
      }
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    updateData (val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getDataList();
    },
    cancelSearch () {
      this.searchForm = {
        page: 1,
        rows: 10,
        status: -1
      }
      this.getDataList(1)
    },
    getDataList (style) {
      if (style) {
        this.searchForm.page = 1
      }
      let obj = {
        page: this.searchForm.page,
        rows: this.searchForm.rows,
      }
      if (this.searchForm.status !== -1) obj.status = this.searchForm.status
      this.$axios.post(this.$api.set.videoNumber.getCatLogList, obj).then(res => {
        if (res.code === 0) {
          this.datalsit = res.result.list
          this.total = res.result.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handelGoodsType () {
      let that = this;
      return {
        lazy: true,
        value: 'id',
        label: 'label',
        lazyLoad (node, resolve) {
          const { level, data } = node;
          let params = {
            level: level + 1,
          };
          if (params.level > 1) {
            params.id = data.id;
          }
          that.$axios.post(that.$api.set.videoNumber.getWxShopCat, params).then(res => {
            if (res.code == 0) {
              let list = res.result.list;
              let nodes = []
              list.map(item => {
                let obj = {
                  id: params.level == 1 ? item.first_cat_id : params.level == 2 ? item.second_cat_id : item.third_cat_id,
                  label: params.level == 1 ? item.first_cat_name : params.level == 2 ? item.second_cat_name : item.third_cat_name,
                  leaf: level >= 2,
                }
                if (level == 2) {
                  obj.qualification = item.qualification
                  obj.qualification_type = item.qualification_type
                  obj.product_qualification_type = item.product_qualification_type
                  obj.product_qualification = item.product_qualification
                }
                nodes.push(obj)
              });
              resolve(nodes);
            } else {
              that.$message.error(res.msg);
            }
          });
        },
      };
    },
    // 分类发生变化时
    typeChange () {
      this.$nextTick(() => {
        this.addVideoGoodsForm.chooseThirdType = this.$refs.cascader.getCheckedNodes()[0].data
      })
    },
    // 获取上传的服务资质
    getCategoryInfo (data) {
      console.log(data)
      data.map(item => {
        this.addVideoGoodsForm.category_info.push(item.path)
      })
    },
    removeCategoryInfo (index) {
      this.addVideoGoodsForm.category_info.splice(index, 1)
    },
    changeCategoryInfo (data) {
      let arr = []
      arr.push(...data)
      this.addVideoGoodsForm.category_info = arr
    },
    // 获取上传的商家资质
    getLicenseInfo (data) {
      data.map(item => {
        this.addVideoGoodsForm.license.push(item.path)
      })
    },
    removeLicenseInfo (index) {
      this.addVideoGoodsForm.license.splice(index, 1)
    },
    changeLicenseInfo (data) {
      let arr = []
      arr.push(...data)
      this.addVideoGoodsForm.license = arr
    },
    sureAddFn () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.addVideoGoodsForm.chooseThirdType.qualification_type && !Number(this.addVideoGoodsForm.chooseThirdType.product_qualification_type)) {
            this.$message.warning('该类目不需要审核资质')
            return
          }
          let obj = {
            scene_group_list: [1],
            category_info: {
              level1: this.addVideoGoodsForm.category_info_level[0],
              level2: this.addVideoGoodsForm.category_info_level[1],
              level3: this.addVideoGoodsForm.category_info_level[2],
              certificate: this.addVideoGoodsForm.category_info
            },
            license: this.addVideoGoodsForm.license,
          }
          this.$axios.post(this.$api.set.videoNumber.auditCategory, obj).then(res => {
            if (res.code === 0) {
              this.$message.success('申请成功,请等待审核结果')
              this.starAddFlag = false
              this.getDataList(1)
            } else {
              this.$message.warning(res.msg)
            }
          })
        }
      })
    },
    dialogClose () {
      this.addVideoGoodsForm = {
        category_info_level: [],
        chooseThirdType: {},
        category_info: [],
        license: []
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;

  .richText {
    white-space: pre-wrap;
    line-height: 30px;
  }
}
</style>